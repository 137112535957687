const get_profile = () => {
  const host = window.location.host;
  if (host === "localhost:3000") return "dev";
  if (host === "docshare-staging.web.app") return "staging";
  if (host === "docshare.cambri.ai") return "prod";
  // Remaining host need to be added here going forth as per env setup
};

let DOMAIN = "https://api.docshare.cambri.ai";
let cloudUrl = "https://ictservice.cambri.ai/api/";

const profile = get_profile();

switch (profile) {
  case "dev":
  case "staging":
    DOMAIN = "https://api.docshare.cambri.ai";
    cloudUrl = "https://ictservice.cambri.ai/api/";
    break;
  case "prod":
    DOMAIN = "https://prod.docshare.api.cambri.ai";
    cloudUrl = "https://ictsystems.cambri.ai/api/";
    break;
  default:
    DOMAIN = "https://api.docshare.cambri.ai";
    cloudUrl = "https://ictservice.cambri.ai/api/";
    break;
}

export const be_url = DOMAIN;

export const commonHeaders = {
  "Content-Type": "application/json",
};

export const DEFAULT_API_CONFIG: string = `${be_url}/api`;

export const cloudServiceUrl = cloudUrl;

export const cloudApplicationUrl =
  get_profile() === "prod" ? "docshare.cambri.ai" : "docshare-staging.web.app";
