import { createSlice } from "@reduxjs/toolkit";
import { Project } from "./projectSlice";
import { GetDocumentType } from "../../Components/Documents/Documents";

export interface DocumentType{
    get_id: string;
    isActive: boolean;
    name: string;
    description: string;
    url: s3UploadedDocType;
    projectId: string;
    folderId:string;
}
export interface FolderType{
    get_id: string;
    isActive: boolean;
    name: string;
    description: string;
    projectId: string;
}
export interface s3UploadedDocType{
    bucketName: string;
    fileName: string;
    folderPath: string;
    region: string;
    timeStamp: string;
}

export interface DocumentsState{
    projectsForDocuments:Array<Project|null>
    folderData:[];
    loading : boolean;
    selectedProject:string;
    documentLoding:boolean;
    documentsOfAProject:Array<GetDocumentType | null>;
    dialogOpenStatus:boolean;
    folderDialogOpenStatus:boolean;
    document:DocumentType;
    folder: FolderType;
    folderDataShow:boolean;
    selectedFolder:string;
    searchFolder:string;
    documentUploadLoading:boolean;
    shareDialogOpen:boolean;
    shareEmailInput:string;
    shareEmailLoading:boolean;
    shareEmailValidity:string;
    shareEmailDocumentId:string;
    documentDownlodViewLoading:boolean;
}


export const intialDocument : DocumentType = {
    get_id: "",
    isActive: true,
    name: "",
    description: "",
    url: {
        bucketName: "",
        fileName: "",
        folderPath: "",
        region: "",
        timeStamp: "",
    },
    projectId: "",
    folderId:"",
  }
  export const intialFolder : FolderType = {
    get_id: "",
    isActive: true,
    name: "",
    description: "",
    projectId: ""
  }

const initialState : DocumentsState = {
    projectsForDocuments : [],
    loading : false,
    selectedProject:'',
    documentLoding:false,
    documentsOfAProject:[],
    dialogOpenStatus:false,
    document:intialDocument,
    folderDialogOpenStatus:false,
    folder:intialFolder,
    folderDataShow:false,
    folderData:[],
    selectedFolder:"",
    searchFolder:"",
    documentUploadLoading:false,
    shareDialogOpen:false,
    shareEmailInput:"",
    shareEmailLoading:false,
    shareEmailValidity:'',
    shareEmailDocumentId:"",
    documentDownlodViewLoading : false,
}

export const documentSlice = createSlice({
    name: 'documentSlice',
    initialState,
    reducers:{
        setProjectsForDocuments:(state,action) => {
            state.projectsForDocuments = action.payload;
        },
        setLoadingForProjects:(state,action) => {
            state.loading = action.payload;
        },
        setSelectedProject:(state,action) => {
            state.selectedProject = action.payload;
        },
        setLoadingForDocuments:(state,action) => {
            state.documentLoding = action.payload;
        },
        setDocumentsForAProject:(state,action) => {
            state.documentsOfAProject = action.payload;
        },
        setDialogStatus:(state,action) => {
            state.dialogOpenStatus = action.payload;
        },
        setFolderDialogStatus:(state,action) => {
            state.folderDialogOpenStatus = action.payload;
        },
        setDocument:(state,action) => {
            state.document = action.payload;
        },
        setFolder:(state,action) => {
            state.folder = action.payload;
        },
        setFolderDataShow:(state,action) => {
            state.folderDataShow = action.payload;
        },
        setFolderData:(state,action) => {
            state.folderData = action.payload;
        },
        setSelectedFolder:(state,action)=>{
            state.selectedFolder = action.payload
        },
        setSearchFolder:(state,action)=>{
            state.searchFolder = action.payload
        },
        setDocumentUploadLoading:(state,action)=>{
            state.documentUploadLoading = action.payload
        },setShareDialogOpen:(state,action)=>{
            state.shareDialogOpen = action.payload
        },
        setShareEmailInput:(state,action)=>{
            state.shareEmailInput = action.payload
        },
        setShareEmailLoading:(state,action)=>{
            state.shareEmailLoading = action.payload
        },
        setShareEmailValidity:(state,action)=>{
            state.shareEmailValidity = action.payload
        },
        setShareDocumentsId:(state,action)=>{
            state.shareEmailDocumentId =action.payload
        },
        setDocumentDownloadViewLoading:(state,action)=>{
            state.documentDownlodViewLoading =action.payload
        },
        resetDocumentState: () => initialState,
    }
})

export const {setProjectsForDocuments,setLoadingForProjects,setSelectedProject,setLoadingForDocuments,setDocumentsForAProject,setDialogStatus,setDocument,setFolderDialogStatus,setFolder,setFolderDataShow,setFolderData,setSelectedFolder,setSearchFolder,setDocumentUploadLoading,setShareDialogOpen,setShareEmailInput,setShareEmailLoading,setShareEmailValidity,setShareDocumentsId,setDocumentDownloadViewLoading,resetDocumentState} = documentSlice.actions;

export default documentSlice.reducer;